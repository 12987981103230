import React from "react";
import CompletedTourList from "./CompletedTourList";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default ({ data }) => {
    const history = useHistory();
    return (
        <>
            <div className="mb-4">
                <p onClick={() => history.goBack()} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faArrowLeft} size="md me-2" /> <h5 className="d-inline-block">Completed Tours</h5>
                </p>
            </div>
            <CompletedTourList data={data} />
        </>
    );
};
