// routes/Routes.js
export const Routes = {
    DashboardOverview: { path: "/" },
    UpcomingTours: { path: "/upcoming-tours" },
    ToursHistory: { path: "/tours-history" },
    CompletedTours: { path: "/completed-tours" },
    Profile: { path: "/profile" },
    Expenses: { path: "/expenses" },
    DriverDetails: { path: "/DriversDetails" },
    Wallet: { path: "/wallet" },
    Signin: { path: "/sign-in" },
    Signup: { path: "/sign-up" },
    VehicleRegistration: {path: '/vehicle-registration'},
    NotFound: { path: "/NotFound/404" },
    Transaction: {path: "/transactions"},
    AdminConfirmation: {path: "/confirmation"},
    LeavePlan: {path:"/leavePlan"},
    Vehicles: {path:"/vehicles"},
    Documents: {path:"/documents"},
    Settings: {path: "/settings"},
    Violations: {path: "/violations"},
    ViolationsDetails: {path: "/violation-details/:violationId"},
    Earnings: {path: "/earnings"},
    AIChat: {path: "/chat"}
};
