import React, { useEffect, useState } from 'react';
import { Card } from '@themesberg/react-bootstrap';
import axios from 'axios';
import { CompletedTourWidget } from '../../../../components/Widgets';
import { RotatingLines } from 'react-loader-spinner';

const CompletedTourList = ({ data }) => {
    const [tours, setTours] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const fetchTours = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/tours/completed?driverId=${data.id}&page=${currentPage}`);
            setTours(response.data.data);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            
        }
        finally{
            setIsLoading(false);
        }
    }, [currentPage]);

    useEffect(() => {
        fetchTours();
    }, [fetchTours]); // Reload tours when currentPage changes

    if (isLoading) {
            return <div className="preloader flex-column justify-content-center align-items-center bg-transparent-white">
                <RotatingLines
                    visible={true}
                    height="96"
                    width="96"
    
                    strokeWidth="5"
                    strokeColor='#262B40'
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>;
        }

    return (
        <div>
            {tours.length === 0 ? (
                <Card><Card.Body>No trips found</Card.Body></Card>
            ) : (
                tours.map((tour) => (
                    <CompletedTourWidget
                        additionalClass="mb-4"
                        bookingId={tour.tourId}
                        tourId={tour.tourName}
                        title={tour.tourListName}
                        pickUpDate={tour.tourDate}
                        pickUpTime={tour.tourTime}
                        pickUpPoint={tour.startLocation}
                        dropPoint={tour.endLocation}
                        fare={tour.fare}
                        iconColor="shape-secondary"
                        mapUrl={tour.mapUrl}
                    />
                ))
            )}
        </div>
    );
};

export default CompletedTourList;
