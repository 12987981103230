import React, { useEffect, useState } from 'react';

import { faCar } from '@fortawesome/free-solid-svg-icons'; // Import faEye icon
import axios from 'axios';
import { CabHistoryWidget, MiniCabHistoryWidget } from '../../../../components/Widgets';

export default ({data}) => {
  console.log(data)
  const [tours, setTours] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const response = await axios.get(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/fetchPastAssignedTours?driverId=${data.id}&page=${currentPage}`);
        setTours(response.data.data);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching tours:', error);
      }
    };
    
    fetchTours();
  }, [currentPage, data.id]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
      <>
        {tours.length === 0 ? (
          <p>No data found.</p>
        ) : (
          <CabHistoryWidget
            bookingId={tours[0].tourId}
            tourId={tours[0].tourName}
            title={tours[0].bookedTourName}
            pickUpDate={tours[0].tourDate}
            pickUpTime={tours[0].tourTime}
            pickUpPoint={tours[0].startLocation}
            dropPoint={tours[0].endLocation}
            fare={tours[0].fare}
            icon={faCar}
            status={tours[0].status}
            iconColor="shape-secondary"
            detailsButtonEnabled="false"
            hover={data.hover}
          />
        )}
      </>
    );
};