import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Card, Nav, Tab } from '@themesberg/react-bootstrap';
import TourList from "./TourList";
import TourListHistory from "./TourHistory/TourListHistory";
import { useHistory, useLocation } from "react-router-dom";


export default ({ data }) => {

  const history = useHistory();
  const location = useLocation(); // To access the current path
  const [activeTab, setActiveTab] = useState("upcoming"); // Default to 'upcoming'

  useEffect(() => {
    // Check the current path
    if (location.pathname === "/upcoming-tours") {
      setActiveTab("upcoming");
    } else if (location.pathname === "/tours-history") {
      setActiveTab("past");
    }
  }, [location]);

  return (
    <>
      <div className="mb-4">
        <p onClick={() => history.goBack()} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faArrowLeft} size="md me-2" /> <h5 className="d-inline-block">Trips</h5>
        </p>
      </div>
      <div className="mb-4">
        <h2 className="mb-3">Upcoming</h2>
        <TourList data={data} />
      </div>
      <div className="mb-4">
        <h2 className="mb-3">Past</h2>
        <TourListHistory data={data} />
      </div>
    </>
  );
};
