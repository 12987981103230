import { faExclamation, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from '@themesberg/react-bootstrap';
import React from 'react';

export default ({ showDefault, handleClose, handleConfirm }) => {
  return (
    <Modal size='sm' border="light" className="shadow mb-6" centered show={showDefault} onHide={handleClose}>
      <Modal.Body>
        <div className='mb-4'>
          <h5 className='fw-normal'><FontAwesomeIcon icon={faExclamationCircle} className='text-danger me-1' /> Are you sure you want to cancel your ride?</h5>
        </div>
        <div className='text-center'>
          <Button
            variant="primary"
            size="md"
            className="animate-up-2 f-12 mx-2"
            onClick={handleClose} // Cancel action, simply closes the modal
          >
            Cancel
          </Button>
          <Button
            variant="danger" // Red color to signify the confirm action
            size="md"
            className="animate-up-2 f-12 mx-2"
            onClick={handleConfirm} // Confirm action, performs the desired logic
          >
            Confirm
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
