import React from "react";
import Profile from "./examples/CabDriver/Profile/Profile";

export default ({data}) => { 
  const updatedData = {...data, userRole: data.role, approvalStatus: "Approved"  }
  return (
    <>
      <Profile data={updatedData} />
    </>
  );
};
