import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button } from '@themesberg/react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Routes } from '../../../routes';

export default ({ data }) => {
    const [walletData, setWalletData] = useState();

    useEffect(() => {
        axios.get(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/walletDetails?driverId=${data.id}`)
            .then(response => {
                setWalletData(response.data);
            })
            .catch(error => {
                console.error('Error fetching wallet data:', error);
            });
    }, [data]);

    return (
        <Row>
            <Col xs={12} md={12}>
                {/* Wallet Details Card */}
                <Card className="bg-D1E5E6">
                    {walletData && (
                        <>
                            <Card.Body>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                            <div>
                                                <p className="mb-1 text-muted f-14">Trips</p>
                                                <h4 className="mb-0 f-18">RM {parseInt(walletData.balance).toFixed(2)}</h4>
                                            </div>
                                            <div>
                                                <p className="mb-1 text-muted f-14">Expenses</p>
                                                <h4 className="mb-0 f-18">RM {parseInt(walletData.totalApprovedExpenses).toFixed(2)}</h4>
                                            </div>
                                        </div>
                                        <Link to={Routes.Wallet.path}>
                                        <div className='border-top-a4c2c4 pt-3'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <h6 className="mb-0 f-14 text-capitalize">Payment history</h6>
                                                <FontAwesomeIcon className="mx-2" size="sm" icon={faAngleRight} />
                                            </div>
                                        </div>
                                        </Link>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </>
                    )}
                </Card>
            </Col>
        </Row>
    );
};
