import React, { useState } from 'react';
import { Card, Button, Form, Alert } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons'; // Import the pen icon
import axios from 'axios';
import moment from 'moment'; // Import moment for date formatting

// Sub-child component for displaying text values
const TextField = ({ field, value, onChange }) => (
    <div>
        <h5 className='text-capitalize'>{field.replace(/([A-Z])/g, ' $1')}</h5>
        <span>{value}</span>
    </div>
);

export default ({ field, value, driverId, onSuccess, onError }) => {
    const [newValue, setNewValue] = useState(value);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false); // Track if the field is being edited

    const handleUpdate = async () => {
        if (newValue === value ) return; // No changes

        setLoading(true);
        setError(null);

        try {
            let updateUrl = '';
            let updateData = {};

            switch (field) {
                case 'firstName':
                    updateUrl = `/driver/${driverId}/firstName`;
                    updateData = { firstName: newValue };
                    break;
                case 'lastName':
                    updateUrl = `/driver/${driverId}/lastName`;
                    updateData = { lastName: newValue };
                    break;
                case 'birthDate':
                    updateUrl = `/driver/${driverId}/birthDate`;
                    updateData = { birthDate: newValue };
                    break;
                case 'contactNumber':
                    updateUrl = `/driver/${driverId}/contactNumber`;
                    updateData = { contactNumber: newValue };
                    break;
                case 'address':
                    updateUrl = `/driver/${driverId}/address`;
                    updateData = { address: newValue };
                    break;
                default:
                    break;
            }

            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}${updateUrl}`,
                updateData
            );

            onSuccess(response.data.message);

            setLoading(false);
            setIsEditing(false); // Stop editing after successful update
        } catch (err) {
            setLoading(false);
            setError(err.response ? err.response.data.message : err.message);
            onError(err.response ? err.response.data.message : err.message);
        }
    };

    const handleEdit = () => {
        setIsEditing(true); // Set to editing mode
    };

    const handleCancel = () => {
        setIsEditing(false);
        setNewValue(value);
    };

    return (
        <Card className='mb-2'>
            <Card.Body>
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        {field === 'joiningDate' ? ( // Check if the field is "joiningDate"
                            <TextField
                                field={field}
                                value={moment(newValue).format('Do MMM YYYY')} // Format the date using moment
                            />
                        ) : (
                            isEditing ? (
                                <div>
                                    <h5 className='text-capitalize'>{field.replace(/([A-Z])/g, ' $1')}</h5>
                                    <Form.Control
                                        type="text"
                                        value={newValue}
                                        onChange={(e) => setNewValue(e.target.value)}
                                    />
                                </div>
                            ) : (
                                <TextField field={field} value={newValue} />
                            )
                        )}
                    </div>
                    <div>
                        {field !== 'joiningDate' && ( // Only show edit and update for non-"joiningDate" fields
                            isEditing ? (
                                <>
                                    <Button variant="secondary" size="sm" className="me-2" onClick={handleCancel}>Cancel</Button>
                                    <Button variant="primary" size="sm" onClick={handleUpdate} disabled={loading}>
                                        {loading ? 'Updating...' : 'Update'}
                                    </Button>
                                </>
                            ) : (
                                <Button variant="light" onClick={handleEdit}>
                                    <FontAwesomeIcon icon={faPen} className='f-12'/>
                                </Button>
                            )
                        )}
                    </div>
                </div>
                {error && <Alert variant="danger">{error}</Alert>}
            </Card.Body>
        </Card>
    );
};
