import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import axios from 'axios';
import LoaderComponent from '../components/LoaderComponent';
import WeekViewCalendar from '../../components/WeekViewCalendar';

export default ({ data }) => {
    const [walletData, setWalletData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const fetchWalletData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/walletDetails?driverId=${data.id}`)
            setWalletData(response.data);
        }
        catch (error) { }
        finally {
            setIsLoading(false);
        }
    }, [data]);

    useEffect(() => {
        fetchWalletData();
    }, [data]);


    return (
        <Row>
            <Card className="bg-transparent mb-3 border-0">
                <div className='d-flex justify-content-between align-items-center'>
                    <h2 className="mb-3">
                        <FontAwesomeIcon icon={faWallet} className="me-2" /> Earnings
                    </h2>
                </div>
            </Card>
            <Col xs={12} md={12} className='mb-4'>
                {/* Wallet Details Card */}
                <Card border="light" className="bg-grey shadow">
                    {isLoading
                        ? <LoaderComponent />
                        : <>
                            <Card.Body>
                                <Row>
                                    <Col xs={12} md={12} className='mb-4'>
                                        <p className="mb-1 f-16">Earnings</p>
                                        <h4 className="mb-2 fs-36">RM {parseInt(walletData.balance).toFixed(2)}</h4>
                                    </Col>
                                </Row>
                                <div className='d-flex justify-content-between align-items-center pb-2'>
                                    <p className="mb-1 f-16">Trips</p>
                                    <h4 className="mb-1 f-16">RM {parseInt(walletData.totalTripsAmount).toFixed(2)}</h4>
                                </div>
                                <div className='d-flex justify-content-between align-items-center border-top pt-2 pb-2'>
                                    <p className="mb-1 f-16">Rewards</p>
                                    <h4 className="mb-1 f-16">RM {parseInt(walletData.totalRewards).toFixed(2)}</h4>
                                </div>
                                <div className='d-flex justify-content-between align-items-center border-top pt-2 pb-2 border-2 mt-2'>
                                    <p className="mb-1 f-16">Payout</p>
                                    <h4 className="mb-1 f-16">RM {parseInt(walletData.totalPayouts).toFixed(2)}</h4>
                                </div>
                            </Card.Body>
                        </>
                    }
                </Card>
            </Col>
        </Row>
    );
};
