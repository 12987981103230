import React, { useState, useEffect } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowCircleRight, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { Routes } from "../routes";
import axios from "axios";
import LoaderComponent from "../pages/components/LoaderComponent";

export default ({data}) => {
  const history = useHistory();
  const [currentDate, setCurrentDate] = useState(moment()); // Tracks the currently displayed date
  const [earnings, setEarnings] = useState();
  const [loading, setLoading] = useState([]);

  // Fetch earnings data from the API
  const fetchEarnings = async (date) => {
    setLoading(true);
    try {
      const response = await axios.put(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/driver/${data.id}/dailyEarnings`, {
        startDate: date.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endDate: date.clone().add('day').endOf('day').format('YYYY-MM-DD HH:mm:ss')
    
      });
      setEarnings(response.data);
    } catch (error) {
      console.error("Error fetching earnings:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchEarnings(currentDate); // Fetch earnings whenever the date changes
  }, [currentDate]);

  // Show the next day
  const handleNextDay = () => {
    setCurrentDate(currentDate.clone().add(1, "days")); // Move to the next day
  };

  // Show the previous day
  const handlePreviousDay = () => {
    setCurrentDate(currentDate.clone().subtract(1, "days")); // Move to the previous day
  };

  const handleCompletedTours = () => {
    return history.push(Routes.CompletedTours.path);
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <FontAwesomeIcon icon={faArrowAltCircleLeft} onClick={handlePreviousDay} />
        <div className="text-center">
          <p className="mb-0 f-14">{currentDate.format("MMM DD")}</p>
          <p className="f-22 fw-bold mt-2 mb-0">RM {earnings.totalAmount}</p>
          <p className="f-14 mt-2 text-uppercase fw-semibold mb-0">Earnings</p>
        </div>
        <FontAwesomeIcon icon={faArrowCircleRight} onClick={handleNextDay} />
      </div>

      <div className="text-center mt-5">
        <div className="d-flex justify-content-between align-items-center pb-5 border-bottom">
          <div>
            <h3>{earnings.completedTours}</h3>
            <p className="f-14 mt-2 text-uppercase fw-semibold mb-0">
              Completed <FontAwesomeIcon icon={faExclamationCircle} onClick={handleCompletedTours} />
            </p>
          </div>
          <div>
            <h3>{earnings.cancelledTours}</h3>
            <p className="f-14 mt-2 text-uppercase fw-semibold mb-0">Cancelled</p>
          </div>
          <div>
            <h3>{earnings.scheduledTours}</h3>
            <p className="f-14 mt-2 text-uppercase fw-semibold mb-0">Scheduled</p>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center pb-2 mt-4">
          <p className="mb-1 f-16">Trip Earnings</p>
          <h4 className="mb-1 f-16">RM {earnings.totalFare}</h4>
        </div>
        <div className="d-flex justify-content-between align-items-center border-top pt-2 pb-2">
          <p className="mb-1 f-16">Rewards</p>
          <h4 className="mb-1 f-16">RM {earnings.totalRewards}</h4>
        </div>
      </div>
    </div>
  );
};
