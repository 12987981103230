import React, { useEffect, useRef, useState } from "react";

const MapWidget = ({ mapString }) => {
  const mapRef = useRef(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    // Function to dynamically load the Google Maps script
    const loadGoogleMapsScript = () => {
      const existingScript = document.getElementById("google-maps-script");
      if (!existingScript) {
        const script = document.createElement("script");
        script.id = "google-maps-script";
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBqvc7HP_JL_pjCHyHvIhoet4jdg25l4ig&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => setIsScriptLoaded(true);
        document.body.appendChild(script);
      } else {
        setIsScriptLoaded(true); // Script is already loaded
      }
    };

    loadGoogleMapsScript();
  }, []);

  useEffect(() => {
    if (!isScriptLoaded || !mapString || !mapRef.current) return;

    const urlParams = new URLSearchParams(mapString);
    
    // Extract the waypoints and destination
    const waypoints = urlParams.get("waypoints"); // Waypoints
    const destination = urlParams.get("destination"); // Destination

    // Update origin to the value of waypoints
    if (waypoints) {
        urlParams.set("origin", waypoints);
        urlParams.delete("waypoints"); // Remove waypoints
    }

    // Rebuild the updated URL string
    const updatedUrl = urlParams.toString();

    const initializeMap = () => {
      // Parse the map string
      const params = new URLSearchParams(updatedUrl);
      const origin = params.get("origin");
      const destination = params.get("destination");
      const waypoints = params.get("waypoints")
        ? params.get("waypoints").split("|").map((loc) => ({ location: loc, stopover: true }))
        : [];

      // Initialize the map
      const map = new window.google.maps.Map(mapRef.current, {
        zoom: 10,
        disableDefaultUI: true, // Disable all controls (zoom, street view, map types, etc.)
        gestureHandling: 'none', // Disable gestures like zooming and dragging
        draggable: false, // Disable dragging
        scrollwheel: false, // Disable scroll zoom
        zoomControl: false, // Disable zoom control
        streetViewControl: false, // Disable street view control
        mapTypeControl: false, // Disable map type control
        fullscreenControl: false, // Disable fullscreen control
    });

      // Initialize DirectionsService and DirectionsRenderer
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer();
      directionsRenderer.setMap(map);

      // Request route
      directionsService.route(
        {
          origin,
          destination,
          waypoints,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(response);
          } else {
            console.error("Directions request failed due to " + status);
          }
        }
      );
    };

    initializeMap();
  }, [isScriptLoaded, mapString]);

  return <div ref={mapRef} style={{ width: "100%", height: "400px", borderRadius: "0.5rem" }} />;
};

export default MapWidget;
