// RoutesConfig.js
import React from 'react';
import { Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// Pages
import Signin from "./examples/Signin";
import Profile from './Profile';
import DriverDetailsPage from './examples/DriversDetailsPage';

// Components
import WithAuth from '../components/Authentication/WithAuth';
import RouteWithLoader from '../RoutesWithLoader';
import RouteWithSidebar from '../RoutesWithSidebar';
import CabDriverUpcomingTours from './examples/CabDriver/CabDriverUpcomingTours';
import DashboardOverview from './dashboard/DashboardOverview';
import TripDetails from './components/TripDetails';
import PostTripDetails from './components/PostTripDetails';
import Wallet from './Wallet';
import DriverSignUpPage from './examples/DriverSignUpPage';
import AdminConfirmation from './examples/AdminConfirmation';
import Transactions from './Transactions';
import VehicleRegistrationPage from './examples/VehicleRegistration';
import LeavePlan from './examples/CabDriver/LeavePlanner/LeavePlan';
import VehicleHistory from './examples/CabDriver/Vehicles/VehicleHistory';
import Documents from './examples/CabDriver/Documents/Documents';
import CompletedTours from './examples/CabDriver/CompletedTours/CompletedTours';
import Settings from './Settings';
import Violations from './examples/CabDriver/Violations/Violations';
import ViolationDetails from './examples/CabDriver/Violations/ViolationDetails';
import Earnings from './examples/CabDriver/Earnings/Earnings';
import Expense from './examples/CabDriver/Expenses/Expense';
import OpenApiChat from '../components/widgets/OpenApiChat';


const RoutesConfig = () => (
  <>
    <Switch>
      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      <RouteWithLoader exact path={Routes.Signup.path} component={DriverSignUpPage} />
      <RouteWithSidebar exact path={Routes.VehicleRegistration.path} component={VehicleRegistrationPage} />
      <RouteWithLoader exact path={Routes.AdminConfirmation.path} component={AdminConfirmation} />
      <RouteWithSidebar exact path="/trip-details/:bookingId/:id" component={WithAuth(TripDetails)} />
      <RouteWithSidebar exact path="/trip-end-details/:bookingId/:id" component={WithAuth(PostTripDetails)} />
      <RouteWithSidebar exact path={Routes.ToursHistory.path} component={WithAuth(CabDriverUpcomingTours)} />
      <RouteWithSidebar exact path={Routes.Transaction.path} component={WithAuth(Transactions)} />
      <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={WithAuth(DashboardOverview)} />
      <RouteWithSidebar exact path={Routes.Profile.path} component={WithAuth(Profile)} />
      <RouteWithSidebar exact path={Routes.Wallet.path} component={WithAuth(Wallet)} />
      <RouteWithSidebar exact path={Routes.UpcomingTours.path} component={WithAuth(CabDriverUpcomingTours)} />
      <RouteWithSidebar exact path={Routes.CompletedTours.path} component={WithAuth(CompletedTours)} />
      <RouteWithSidebar exact path={Routes.DriverDetails.path} component={WithAuth(DriverDetailsPage)} />
      <RouteWithSidebar exact path={Routes.ToursHistory.path} component={WithAuth(CabDriverUpcomingTours)} />
      <RouteWithSidebar exact path={Routes.LeavePlan.path} component={WithAuth(LeavePlan)} />
      <RouteWithSidebar exact path={Routes.Vehicles.path} component={WithAuth(VehicleHistory)} />
      <RouteWithSidebar exact path={Routes.Documents.path} component={WithAuth(Documents)} />
      <RouteWithSidebar exact path={Routes.Settings.path} component={WithAuth(Settings)} />
      <RouteWithSidebar exact path={Routes.Violations.path} component={WithAuth(Violations)} />
      <RouteWithSidebar exact path={Routes.ViolationsDetails.path} component={WithAuth(ViolationDetails)} />
      <RouteWithSidebar exact path={Routes.Earnings.path} component={WithAuth(Earnings)} />
      <RouteWithSidebar exact path={Routes.Expenses.path} component={WithAuth(Expense)} />
      <RouteWithSidebar exact path={Routes.AIChat.path} component={WithAuth(OpenApiChat)} />

      <Redirect to={Routes.NotFound.path} />
    </Switch>
  </>
);

export default RoutesConfig;
