import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TripAssignedModal from '../widgets/TripAssignedModal';
export default ({data, handle}) => {
    const {email, username} = data;
    const [ws, setWs] = useState(null);
    const [newTrip, setNewTrip] =  useState(null);
    const [isNewTrip, setIsNewTrip] =  useState(false);

    useEffect(() =>{
        const fetchNewTasks = async (driverId) => {
            try {
                const userDataResponse = await axios.get(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/fetchActionsOnAssignedTours?driverId=${driverId}`);
                if(userDataResponse.data.length > 0){
                    setNewTrip(userDataResponse.data[0])
                    setIsNewTrip(true);                    
                }
            } catch (error) {
            }
        };
        fetchNewTasks(data.id)

    }, [data]);

    useEffect(() =>{
        if(newTrip !== null){
            setIsNewTrip(true);
        }
    }, [newTrip])

    useEffect(() => {
        // Replace `YOUR_WEBSOCKET_URL` with your WebSocket API endpoint
        const url =`wss://j6pviturf3.execute-api.ap-southeast-1.amazonaws.com/dev?username=${username}&email=${email}`
        const socket = new WebSocket(url);

        socket.onopen = () => {
            console.log("trip")
        };
        socket.onmessage = (event) => {
            console.log(event, "msmsmsm")
            const data = JSON.parse(event.data);
            const {tripData} = data.message            
            if(tripData && tripData.length > 0){
                setNewTrip(tripData[0]);
            }
        };

        setWs(socket);

        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, []);

    return (
        <>
            {isNewTrip && <TripAssignedModal data={newTrip} />}
        </>
    );
};