import React, { useEffect, useState } from 'react';
import { Card } from '@themesberg/react-bootstrap';
import { faTaxi } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { CabHistoryWidget } from '../../../../components/Widgets';

const TourListHistory = ({ data }) => {
  const [tours, setTours] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const response = await axios.get(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/fetchPastAssignedTours?driverId=${data.id}&page=${currentPage}`);
        const groupedTours = response.data.data.reduce((acc, tour) => {
          const bookingDate = tour.tourDate;
          // Check if the bookingDate already exists in the accumulator
          let existingGroup = acc.find(group => group.bookingDate === bookingDate);

          if (existingGroup) {
            // If a group for the same bookingDate exists, push the current tour into it
            existingGroup.data.push(tour);
          } else {
            // Otherwise, create a new group for the bookingDate
            acc.push({
              bookingDate: bookingDate,
              data: [tour]
            });
          }

          return acc;
        }, []);
        setTours(groupedTours);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching tours:', error);
      }
    };

    fetchTours();
  }, [currentPage, data.id]);

  return (
    <div>
      {tours.length === 0 ? (
        <Card><Card.Body>No trips found</Card.Body></Card>
      ) : (
        tours.map((tourGroup, index) => (
          <div key={index} className='mb-4'>
            {tourGroup.data.map(tour => (
              <CabHistoryWidget
                key={tour.tourId}
                bookingId={tour.tourId}
                tourId={tour.tourName}
                title={tour.bookedTourName}
                pickUpDate={tour.tourDate}
                pickUpTime={tour.tourTime}
                pickUpPoint={tour.startLocation}
                dropPoint={tour.endLocation}
                fare={tour.fare}
                icon={faTaxi}
                status={tour.status}
                iconColor="shape-secondary"
                detailsButtonEnabled="false"
                hover="false"
              />
            ))}
          </div>
        ))
      )}
    </div>
  );
};

export default TourListHistory;
