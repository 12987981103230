
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHistory, faFolder, faWallet, faBan } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Routes } from "../routes";

export default ({ data }) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <div className="d-flex align-items-center">
            <span className="sidebar-icon me-2"><FontAwesomeIcon icon={icon} /> </span>
            <span className="sidebar-text f-16">{title}</span>
          </div>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <div className="sidebar-inner py-4">
        <Nav className="flex-column">
          <NavItem title="Profile" link={Routes.Profile.path} icon={faUser} />
          <NavItem title="Vehicle Records" link={Routes.Vehicles.path} icon={faHistory} />
          <NavItem title="My Documents" link={Routes.Documents.path} icon={faFolder} />
          <NavItem title="Payment History" link={Routes.Wallet.path} icon={faWallet} />
          <NavItem title="Violation Records" link={Routes.Violations.path} icon={faBan} />
          <NavItem title="Expenses Tracker" link={Routes.Expenses.path} icon={faBan} />
        </Nav>
      </div>
    </>
  );
};
