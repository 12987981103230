import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Breadcrumb, Form, Button, InputGroup, Accordion } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEdit, faSave, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import moment from 'moment-timezone';
import Preloader from './Preloader';
import Datetime from 'react-datetime';
import { ChoosePhotoWidget, ProfileCardWidget } from './Widgets';
import VehicleComponent from './SubComponents/VehicleComponent';
import SettingsNav from './SettingsNav';

export default ({ userData }) => {
  const [user, setUser] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    email: '',
    contactNumber: '',
    address: '',
    joinDate: '',
    approvalStatus: '',
    driverLicence: '',
    validUntil: '',
    carNumber: '',
    carModel: '',
    licenseExpiryDate: ''
  });
  const [editMode, setEditMode] = useState(false);

  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios.get(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/fetchDriverProfile?driverId=${userData.id}`);
      setUser(response.data); // Assuming response.data contains user details
    } catch (error) {
      // Handle error state or show error message
    }
  }, [userData]);

  useEffect(() => {
    fetchUserData();
  }, [userData]);

  useEffect(() => {
    if (user !== null) {
      setFormData({
        firstName: user.firstName,
        lastName: user.lastName,
        birthDate: moment(user.birthDate).format('YYYY-MM-DD'),
        email: user.email,
        contactNumber: user.contactNumber,
        address: user.address,
        joinDate: moment(user.joiningDate).format('YYYY-MM-DD'),
        approvalStatus: user.approvalStatus || 'approved',
        driverLicence: user.driverLicence,
        licenseExpiryDate: user.licenseExpiryDate,
        validUntil: user.validUntil,
        carNumber: user.carNumber,
        carModel: user.carModel
      });
      setLastUpdated(user.updatedAt);
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (field) => (date) => {
    setFormData(prevState => ({ ...prevState, [field]: date.format('YYYY-MM-DD') }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put('https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/driver/update', formData);
      setUser(response.data); // Assuming response.data contains updated user details
      fetchUserData();
      setEditMode(false); // Exit edit mode after successful update
    } catch (error) {
      // Handle error state or show error message
    }
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  if (!user) {
    return <><Preloader /></>; // You can customize loading indicator as per your design
  }

  return (
    <div>
      <Row className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div className="d-block mb-2 mb-md-0">
          {/* <div className="d-flex justify-content-between align-items-center">
            <h4>My Profile
              <span className='f-12 mt-1 d-block text-gray fw-normal'>Last updated on {moment(lastUpdated).format('dddd')} {moment(lastUpdated).format('Do MMMM, YYYY')}</span>
            </h4>
            {!editMode && (
              <Button variant="white" className="text-dark me-2 dropdown-toggle" onClick={toggleEditMode}>
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
          </div> */}
        </div>
      </Row>
      <div>
        <ProfileCardWidget photo={user.image} userName={`${user.firstName} ${user.lastName}`} contactNo={user.contactNumber} email={user.email} />
      </div>
      <SettingsNav data={userData} />
    </div>
  );
};