import React, { useState, useEffect, useRef, useCallback } from "react";
import { Image, Dropdown, Nav, ListGroup, Row, Col, Button } from "@themesberg/react-bootstrap";
import Bell from "../../assets/img/icons/bell.svg";
import axios from "axios";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";

const NotificationsDropdown = ({ data }) => {
    const [driverId, setDriverId] = useState(data.id);
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [offSet, setOffset] = useState(10);
    const containerRef = useRef(null);
    const history = useHistory();

    const fetchNotifications = useCallback(
        async (page = 1) => {
            if (page > totalPages) return; // Prevent unnecessary requests
            setIsLoading(true);
            try {
                const offset = (page - 1) * perPage; // Correct offset calculation
                const response = await axios.get(
                    `https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/notifications/drivers`,
                    {
                        params: { driverId, limit: perPage, offset },
                    }
                );
                const { data, totalPages } = response.data;
                setNotifications((prev) => [...prev, ...data]); // Append new data
                console.log("Fetched Data:", data); // Log fetched data
                setTotalPages(totalPages);
                setCurrentPage(page);
            } catch (error) {
                console.error("Error fetching notifications:", error);
            } finally {
                setIsLoading(false);
            }
        },
        [driverId, perPage, totalPages]
    );    

    useEffect(() => {
        fetchNotifications(1);
    }, [fetchNotifications]);

    const handleScroll = useCallback(() => {
        const container = containerRef.current;
        if (!container) return;

        const { scrollTop, scrollHeight, clientHeight } = container;
        if (scrollHeight - scrollTop <= clientHeight + 10 && !isLoading && currentPage < totalPages) {
            fetchNotifications(currentPage + 1);
        }
    }, [fetchNotifications, isLoading, currentPage, totalPages]);

    const markNotificationsAsRead = async (id = null) => {
        try {
            if (id) {
                await axios.post(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/notifications/drivers/markRead`, { userType: "driver", notificationId: id })
            }
            else {
                await axios.post(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/notifications/drivers/markAllRead?userId=${driverId}&userType=driver&limit=${perPage}&offset=${offSet}`);
            }
            refreshNotifications();
        } catch (error) {
            console.error("Error marking notifications as read:", error);
        }
    };

    const refreshNotifications = async () => {
        setNotifications([]); // Clear current notifications to avoid duplication
        setCurrentPage(1);    // Reset pagination to the first page
        await fetchNotifications(1); // Fetch the updated notifications
    };

    const formatCreatedAt = (createdAt) => {
        const malaysiaTimezone = "Asia/Kuala_Lumpur"; // Malaysia timezone
        const now = moment.tz(moment(), malaysiaTimezone); // Current time in Malaysia
        const created = moment.tz(createdAt, malaysiaTimezone); // Created time in Malaysia
        const minutesAgo = now.diff(created, "minutes");
        const hoursAgo = now.diff(created, "hours");
        const daysAgo = now.diff(created, "days");

        if (minutesAgo < 1) return "Just now";
        if (minutesAgo < 60) return `${minutesAgo} min${minutesAgo > 1 ? "s" : ""} ago`;
        if (hoursAgo < 24) return `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;
        if (daysAgo === 1) return "1 day ago";
        if (daysAgo <= 7) return `${daysAgo} days ago`; // Show exact days for up to a week
        return created.format("D MMM, YYYY, h:mm A"); // Show full date for older times
    };

    const NotificationItem = ({ id, createdAt, message, isRead }) => {
        const updatedCreateAt = formatCreatedAt(createdAt);
        const readClassName = isRead ? "" : "text-danger";

        return (
            <ListGroup.Item className="border-bottom border-light" key={`notification-${id}`}>
                <Row className="align-items-center">
                    <Col>
                        <p className="font-small mt-1 mb-2">{message}</p>
                    </Col>
                    <div className="d-flex justify-content-between align-items-center">
                        <div class="text-end f-12"><small class={readClassName}>{updatedCreateAt}</small></div>
                        {!isRead && (
                            <Button variant="link" size="sm" className="f-12" onClick={() => markNotificationsAsRead(id)}>
                                Mark Read
                            </Button>
                        )}
                    </div>
                </Row>
            </ListGroup.Item>
        );
    };

    //   const showAllNotifications = () => {
    //     history.push(`/notifications`);
    //   };

    return (
        <Dropdown as={Nav.Item}>
            <Dropdown.Toggle as={Nav.Link} className="icon-notifications py-0">
                <span className="icon navbar-avatar-20">
                    <Image src={Bell} className="rounded-circle text-dark bell-shake" />
                    {notifications.some((notif) => !notif.isRead) && (
                        <span className="icon-badge rounded-circle unread-notifications" />
                    )}
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                    <Nav.Link
                        className="text-center text-primary fw-bold border-bottom border-light py-3"
                    // onClick={showAllNotifications}
                    >
                        Notifications
                    </Nav.Link>
                    {notifications.length > 0 && notifications.some((notif) => !notif.isRead) && (

                        <Button
                            variant="primary"
                            className="m-3"
                            size="sm"
                            onClick={() => markNotificationsAsRead()}
                            disabled={notifications.every((notif) => notif.read)}
                        >
                            Mark All Read
                        </Button>)
                    }
                    <div
                        style={{ maxHeight: "600px", overflowY: "auto" }}
                        ref={containerRef}
                        onScroll={handleScroll}
                    >
                        {notifications.map((n) => (
                            <NotificationItem key={`notification-${n.id}`} {...n} />
                        ))}
                        {isLoading && (
                            <ListGroup.Item className="text-center">
                                <span>Loading...</span>
                            </ListGroup.Item>
                        )}
                        {!isLoading && notifications.length === 0 && (
                            <ListGroup.Item className="text-center">
                                <span>No notifications</span>
                            </ListGroup.Item>
                        )}
                    </div>
                    {/* <Dropdown.Item
            className="text-center text-primary fw-bold py-3"
            onClick={showAllNotifications}
          >
            View all
          </Dropdown.Item> */}
                </ListGroup>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default NotificationsDropdown;