import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Col, Row, Image, Button, Card } from '@themesberg/react-bootstrap';
import { CounterWidget, OngoingTripsWidget } from '../../components/Widgets';
import { faAngleRight, faCar } from '@fortawesome/free-solid-svg-icons';
import Profile from "../../assets/img/driver/a95297b322004db9.svg";
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TourListings from '../examples/CabDriver/TourHistory/TourListings';
import { Link, Redirect } from 'react-router-dom';
import { Routes } from '../../routes';
import LoaderComponent from '../components/LoaderComponent';
import MiniWalletCard from '../examples/widget/MiniWalletCard';
import EarningsToday from '../examples/widget/EarningsToday';
import PreviousTripWidget from '../examples/CabDriver/TourHistory/PreviousTripWidget';
import WelcomeWidget from '../examples/widget/WelcomeWidget';
import OpenApiChat from '../../components/widgets/OpenApiChat';

const CabDriverDashboard = ({ data }) => {
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState('');
  const [nextTrip, setNextTrip] = useState(null);
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [isTripStatusUpdated, setTripStatusUpdated] = useState();


  const [analyticsData, setAnalyticsData] = useState({
    totalAssignedTours: 0,
    totalCompletedTours: 0,
    totalRevenue: 0,
    totalRides: 0,
    totalExpenses: 0,
    recentTrips: [],
    upcomingTrips: null,
    onGoingTrips: null
  });

  useEffect(() => {
    const fetchDriverAnalytics = async () => {
      try {
        const response = await axios.get(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/driverAnalytics?driverId=${data.id}`);
        setAnalyticsData(response.data);
        const upcomingTrip = findNextTrip(response.data.upcomingTrips);

        setNextTrip({
          ...upcomingTrip,
          tourTime: moment(`${upcomingTrip.tourDate} ${upcomingTrip.tourTime}`, 'YYYY-MM-DD HH:mm:ss').toDate()
        });

        const interval = setInterval(() => {
          setNextTrip(currentTrip => {
            if (!currentTrip) return null;
            const momentTime = moment(currentTrip.tourTime);
            momentTime.subtract(1, 'seconds');
            const formattedTime = momentTime.toISOString();
            return { ...currentTrip, tourTime: formattedTime };
          });
        }, 1000);

        setLoading(false); // Set loading to false after data is fetched
        return () => clearInterval(interval);
      } catch (error) {
        setLoading(false); // Ensure loading state is false even on error
      }
    };

    setUserName(data.username);
    setUserId(data.id);
    fetchDriverAnalytics();
  }, [data, isTripStatusUpdated]);

  const findNextTrip = (trips) => {
    if (!trips || trips.length === 0) return null;

    const pendingTrips = trips
      .filter(trip => trip.status === 'pending')
      .sort((a, b) => moment(a.tourDate).isAfter(moment(b.tourDate)) ? 1 : -1);

    return pendingTrips.length > 0 ? pendingTrips[0] : null;
  };

  const getTripDetails = () => {
    return <Redirect to={Routes.ToursHistory} />;
  }

  if (loading) {
    return <LoaderComponent />;
  }

  const handleTripEnded = () => {
    setTripStatusUpdated(prev => !prev);
  };

  const handleTripStart = () => {
    setTripStatusUpdated(prev => !prev);
  }

  return (
    <>
      <Row>
        <Col md={12}>
          {/* <WebSocketDemo /> */}
          {/* <div className="py-4 pb-1">
            <h3 className="upperCase-keyword"> Welcome {userName}</h3>
            <span>Track your trips activity, rides and rewards.</span>
          </div> */}
          <div className='mb-4'>
            <WelcomeWidget data={data} />
          </div>
          <div className='mb-4 shadow'>
            <EarningsToday data={data} />
          </div>
          <div className='mb-4 shadow'>
            <MiniWalletCard data={data} />
          </div>
          <Card className='shadow mb-4'>
            <Card.Body>
              <Row>
                <Col xs={12} md={12}>
                  <div className='mb-3'>
                    <h4 className="mb-3 text-muted">Ongoing Trip</h4>
                    {analyticsData.onGoingTrips.length > 0 ? (
                        analyticsData.onGoingTrips.map((tour) => (
                        <OngoingTripsWidget
                          driverId={userId}
                          bookingId={tour.tourId}
                          tourId={tour.tourName}
                          title={tour.tourListName}
                          pickUpDate={tour.tourDate}
                          pickUpTime={tour.tourTime}
                          pickUpPoint={tour.startLocation}
                          dropPoint={tour.endLocation}
                          fare={tour.fare}
                          icon={faCar}
                          iconColor="shape-primary"
                          status={tour.status}
                          onTripEnded={handleTripEnded}
                          paymentStatus={tour.paymentStatus}
                          mapUrl={tour.mapUrl}
                        />
                      ))) : (
                      <h6 className='mb-0'>You have no ongoing trip</h6>
                    )}

                  </div>
                  <Link to={Routes.UpcomingTours.path}>
                    <div className='border-top-f4f2f2 pt-3'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <h6 className="mb-0 f-14 text-capitalize">
                          Upcoming Trips ({analyticsData.upcomingTrips[0].count})
                        </h6>
                        <Button className="btn-transparent" onClick={getTripDetails} disabled={nextTrip === null}>
                          <FontAwesomeIcon icon={faAngleRight} />
                        </Button>
                      </div>
                    </div>
                  </Link>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <div>
            <Card className='shadow'>
              <Card.Body>
                <Row>
                  <Col xs={12} md={12}>
                    <div className='mb-3'>
                      <h4 className="mb-3 text-muted">Previous Trip</h4>
                      <PreviousTripWidget data={{...data, hover: false}} hover={false} />
                    </div>
                    <Link to={Routes.ToursHistory.path}>
                      <div className='border-top-f4f2f2 pt-3'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <h6 className="mb-0 f-14 text-capitalize">
                            More Trips
                          </h6>
                          <Button className="btn-transparent" onClick={getTripDetails} disabled={nextTrip === null}>
                            <FontAwesomeIcon icon={faAngleRight} />
                          </Button>
                        </div>
                      </div>
                    </Link>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CabDriverDashboard;