import React from 'react';
import { Row, Col } from '@themesberg/react-bootstrap';
import WalletCard from './examples/WalletCard';
import TransactionCard from './examples/TransactionCard';
import ExpensesCard from './examples/ExpensesCard';
import MiniAnalyticsCards from './examples/MiniAnalyticsCards';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Wallet = ({ data }) => {
  const history = useHistory();
  return (
    <>
      <div className="mb-4">
        <p onClick={() => history.goBack()} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faArrowLeft} size="md me-2" /> <h5 className="d-inline-block">Payments</h5>
        </p>
      </div>

      <Row>
        <Col xs={12} md={12}>
          {/* Wallet Details Card */}
          <WalletCard data={data} />
          {/* Tiles cards */}
          {/* <MiniAnalyticsCards data={data} /> */}
          {/* Tracking History Card */}
          <TransactionCard data={data} />
          {/* Expense */}
          <ExpensesCard data={data} />
        </Col>
      </Row>
    </>
  );
};

export default Wallet;
