import React, { useState } from 'react';
import { Button, Modal } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faLocationArrow, faMapMarkerAlt, faUsers, faWallet } from '@fortawesome/free-solid-svg-icons';
import toast, { Toaster } from 'react-hot-toast';
import ShortenAddress from '../Utility/ShortenAddress';
import FormatTz from '../Utility/FormatTz';

const TripAssignedModal = ({ data, handleTripAssignedAction}) => {
  const [viewModal, setViewModal] = useState(true);
  const handleAccept = () => {
    axios.post('https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/acceptTrip', { tourId: data.tourId, driverId: data.driverId })
      .then(response => {
        setViewModal(false);
        handleTripAssignedAction();
        toast.success("You have successfully accepted the trip.")
      })
      .catch(error => {
        toast.error("Failed to accept the trip. Please try again.")
      })
      .finally(() =>{
        window.location.reload(); // Refresh the page after accepting or declining the trip
      })

  };

  // const handleDecline = () => {
  //   // Make an API call to decline the trip
  //   axios.post('https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/declineTrip', { tourId: data.tourId, driverId: data.driverId })
  //     .then(response => {
  //       // Handle success
  //       setViewModal(false);
  //       handleTripAssignedAction();
  //       toast.success("You have successfully declined the trip.")
  //     })
  //     .catch(error => {
  //       toast.error("Failed to decline the trip. Please try again.")
  //     });
  // };

  return (
    <Modal show={viewModal}  className='mx-0 bottom-mobile-modal'>
      <Modal.Header className='pb-1'>
        <Modal.Title className='d-flex justify-content-between align-items-center w-100 responsive-modal-font-title'>
            {/* {data.bookedTourName} */} 
          <span className='f-14 mt-1'>
          <FontAwesomeIcon icon={faClock} className='me-1' />  {FormatTz(data.tourDate)} {moment(data.tourTime, 'HH:mm:ss').format('hh:mm A')}
          </span>
          <span className='f-14 mt-1'>
            <FontAwesomeIcon icon={faUsers} /> {data.pax}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='py-2'>
        <div className='modal-prompt-body'>
          <p className='mb-2'>
            <div className="d-flex justify-content-start align-items-center">
                <span className='text-center w-20'>
                  <FontAwesomeIcon icon={faLocationArrow} className="progress-label text-secondary mt-1" />
                </span>
                <div className="px-2">
                <p className='mb-0 f-14'>
                        {ShortenAddress(data.startLocation)}
                    </p>
                </div>
            </div>
          </p>
          <p className='mb-0'>
            <div className="d-flex justify-content-start align-items-center">
              <span className='text-center w-20'>  
                <FontAwesomeIcon icon={faMapMarkerAlt} className="progress-label text-tertiary mt-1" />
              </span>
              <div className="px-2">
                  <p className='mb-0 f-14'>
                      {ShortenAddress(data.endLocation)}
                  </p>
              </div>
            </div>
          </p>
        </div>
        {/* Additional details or actions can be added here */}
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        <p className='mb-0'>
          <div className="d-flex justify-content-start align-items-center">
              <span className='text-center w-20'>
                <FontAwesomeIcon icon={faWallet} className="progress-label text-secondary mt-1" />
              </span>
              <div className="px-2">
                <p className='mb-0 f-14 fw-bold'>
                  <span class="f-10 d-iblock vta-m">RM</span>  {data.fare}
                </p>
              </div>
          </div>
        </p>
        <div className='d-flex justify-content-end'>
          <Button variant="success" onClick={handleAccept}>
            Accept
          </Button>
        </div>
        {/* Additional buttons or actions */}
      </Modal.Footer>
    </Modal>
  );
};

export default TripAssignedModal;
