import React, { useState } from 'react';
import { Card, Button, Form, Alert } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSyncAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

// Sub-child component for displaying and editing text values
const TextField = ({ field, value }) => (
  <div>
    <h5 className="text-capitalize">{field.replace(/([A-Z])/g, ' $1')}</h5>
    <span>{value}</span>
  </div>
);

// Sub-child component for displaying and editing images with upload
const ImageField = ({ value }) => (
  <div>
    <h5>Image</h5>
    {value && <img src={value} alt="Driver" className="img-fluid rounded" />}
  </div>
);

export default ({ field, value, driverId, onSuccess, onError }) => {
  const [newValue, setNewValue] = useState(value);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const uploadFile = async (file) => {
    const base64File = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
    const response = await axios.post(
      `https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/file-uploader?bucketType=profileImage&filename=${encodeURIComponent(file.name)}`,
      base64File,
      { headers: { 'Content-Type': file.type } }
    );
    return response.data.message.fileLocation;
  };

  const handleUpdate = async () => {
    if (newValue === value && !selectedImage) return;

    setLoading(true);
    setError(null);

    try {
      let updateUrl = '';
      let updateData = {};

      if (selectedImage) {
        const uploadedImageUrl = await uploadFile(selectedImage);
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/${driverId}/profileImage`,
          { image: uploadedImageUrl }
        );
        setNewValue(response.data.image);
        onSuccess('Image updated successfully!');
      } else {
        if (field === 'driverLicence') {
          updateUrl = `/driver/${driverId}/license`;
          updateData = { driverLicence: newValue };
        } else if (field === 'validUpTo') {
          updateUrl = `/driver/${driverId}/license`;
          updateData = { validUpTo: newValue };
        }

        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}${updateUrl}`,
          updateData
        );

        onSuccess(response.data.message);
      }

      setLoading(false);
      setIsEditing(false);
    } catch (err) {
      setLoading(false);
      setError(err.response ? err.response.data.message : err.message);
      onError(err.response ? err.response.data.message : err.message);
    }
  };

  const handleEdit = () => setIsEditing(true);
  const handleCancel = () => {
    setIsEditing(false);
    setNewValue(value);
    setSelectedImage(null);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const MAX_SIZE_MB = 5;
    const ALLOWED_EXTENSIONS = ['image/jpeg', 'image/png', 'image/gif'];

    if (file) {
      if (file.size > MAX_SIZE_MB * 1024 * 1024) {
        onError('File size exceeds 5 MB.');
        return;
      }
      if (!ALLOWED_EXTENSIONS.includes(file.type)) {
        onError('Invalid file format.');
        return;
      }
      setSelectedImage(file);
    }
  };

  return (
    <Card className="mb-2">
      <Card.Body className="d-flex flex-column">
        <div className="mb-3">
          {isEditing ? (
            field === 'image' ? (
              <div>
                <h5>Image</h5>
                <input type="file" accept="image/*" onChange={handleImageChange} />
                {selectedImage && (
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Preview"
                    className="img-fluid rounded mt-2"
                  />
                )}
              </div>
            ) : (
              <div>
                <h5 className="text-capitalize">{field.replace(/([A-Z])/g, ' $1')}</h5>
                <Form.Control
                  type="text"
                  value={newValue}
                  onChange={(e) => setNewValue(e.target.value)}
                />
              </div>
            )
          ) : field === 'image' ? (
            <ImageField value={newValue} />
          ) : (
            <TextField field={field} value={newValue} />
          )}
        </div>
        <div className="d-flex justify-content-between">
          {isEditing ? (
            <>
              <Button variant="success" size="sm" className="me-2" onClick={handleUpdate} disabled={loading}>
                {loading ? <FontAwesomeIcon icon={faSyncAlt} spin /> : 'Save'}
              </Button>
              <Button variant="secondary" size="sm" onClick={handleCancel}>
                <FontAwesomeIcon icon={faTimes} /> Cancel
              </Button>
            </>
          ) : (
            <Button variant="primary" size="sm" onClick={handleEdit}>
              <FontAwesomeIcon icon={faPen} /> Edit
            </Button>
          )}
        </div>
        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
      </Card.Body>
    </Card>
  );
};
