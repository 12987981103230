import React from 'react';
import { Row, Col, Card, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faClock } from '@fortawesome/free-solid-svg-icons';
import TransactionCard from '../subComponents/TransactionCard';
import { Link } from 'react-router-dom';

export default ({ data }) => {
  return (
    <Row className=' mt-4 mb-4'>
      <Col xs={12} md={12}>
        <Card className="bg-transparent mb-3 border-0">
          <div className='d-flex justify-content-between align-items-center'>
            <h2 className="mb-3">
                <FontAwesomeIcon icon={faClock} className="me-2" /> Transactions
            </h2>
            <Link to="/transactions">
               <FontAwesomeIcon icon={faAngleRight} />
            </Link>
          </div>
        </Card>
        < TransactionCard data={data} limit={5} />
      </Col>
    </Row>
  );
};
