import React from 'react';
import { Card } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';

export default ({ vehicle, category, background }) => {
    return (
        <>
            <Card className='mb-2'>
                <Card.Body className={`${background ? background : ''} `}>

                    <div className='d-flex justify-content-between align-items-center'>
                        <h5 className='text-capitalize'>{vehicle.carModel}</h5>
                        <p className="mb-0 f-14">Licence Number: <span className='fw-semibold'>{vehicle.licensePlateNumber}</span></p>
                    </div>

                    <span className='f-14'>
                        {category === 'current' ? (
                            <>
                                <strong>Assigned on:</strong> {moment(vehicle.assignedAt).format('Do MMM, YYYY')}
                            </>
                        ) : (
                            // For past vehicles, show the full range from assignedAt to assignedUntil
                            `${moment(vehicle.assignedAt).format('Do MMM, YYYY')} - ${moment(vehicle.assignedUntil).format('Do MMM, YYYY')}`
                        )}
                    </span>

                </Card.Body>
            </Card>
        </>
    );
};