import React from 'react';
import { RotatingLines } from 'react-loader-spinner';

const LoaderComponent = () => {
  return (
    <div className="preloader flex-column justify-content-center align-items-center bg-transparent-white">
                <RotatingLines
                    visible={true}
                    height="96"
                    width="96"
    
                    strokeWidth="5"
                    strokeColor='#262B40'
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
  );
};

export default LoaderComponent;
